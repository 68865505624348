<template>
  <div id="overlay" @click="off()" style="color: white; align-items: center">
    <div
      style="margin-bottom: -350px; width: 2.5rem; height: 2.5rem"
      class="spinner-grow"
      role="status"
    ></div>
  </div>

  <div style="background-color: #e8f1f5" class="wrapper fadeInDown">
    <div id="formContent">
      <form
        id="formItems"
        class="needs-validation"
        autocomplete="off"
        novalidate
      >
        <!-- <button
          @click="goBack"
          type="button"
          class="btn btn-sm btn-light-danger d-flex justify-content-start"
        >
          <i style="font-size: 18px" class="bi bi-arrow-left-square-fill"></i>
        </button> -->
        <!-- Tabs Titles -->

        <!-- Icon -->
        <div class="fadeIn first pb-10 pe-10 ps-10 pt-0">
          <img
            src="../../public/media/logos/belucalogo.svg"
            id="icon"
            alt="User Icon"
          />
        </div>

        <!-- Login Form -->
        <input
          v-model="form.username"
          type="text"
          id="username"
          ref="username"
          class="form-control fadeIn second"
          placeholder="Username"
          required
        />
        <div class="invalid-feedback text-end pe-8 pb-3">
          <span>กรุณากรอก</span>
        </div>
        <input
          v-model="form.email"
          type="text"
          id="email"
          class="form-control fadeIn second"
          placeholder="Email"
          required
        />
        <div class="invalid-feedback text-end pe-8 pb-3">
          <span>กรุณากรอก</span>
        </div>

        <!-- password -->
        <div style="position: relative">
          <input
            v-model="form.password"
            @input="passwordChange(form.password)"
            :type="showPassword ? 'text' : 'password'"
            id="password"
            class="form-control fadeIn third"
            placeholder="Password"
            required
            maxlength="6"
          />
          <button
            type="button"
            class="fadeIn third"
            @click.prevent="showPassword = !showPassword"
            id="btnPass"
            @mouseover="colorEyePass = 'color: white;'"
            @mouseleave="colorEyePass = 'color: grey;'"
            style="
              cursor: pointer;
              position: absolute;
              top: 30%;
              right: 10%;
              border-radius: 55%;
              border-color: transparent;
            "
          >
            <i class="far fa-eye" :style="colorEyePass"></i>
          </button>
        </div>
        <div class="row d-flex justify-space-between">
          <div class="col-4 text-start ps-14 fadeIn third">
            <span>{{ counterPass }} / 6</span>
          </div>
          <div class="col-8 text-end pe-14" style="color: #f1416c">
            <span v-if="textPassError">{{ textPassError }}</span>
          </div>
        </div>
        <div v-if="!form.password" class="invalid-feedback text-end pe-8 pb-3">
          <span>กรุณากรอก</span>
        </div>

        <!-- confirmPassword -->
        <div style="position: relative">
          <input
            v-model="form.confirmPassword"
            @input="confirmPasswordChange(form.confirmPassword)"
            :type="showConfirmPassword ? 'text' : 'password'"
            id="confirmPassword"
            class="form-control fadeIn third"
            placeholder="Confirm Password"
            :disabled="passwordEmpty"
            required
            :style="!passwordEmpty ? '' : 'background-color: lightGrey'"
            maxlength="6"
          />
          <button
            type="button"
            class="fadeIn third"
            @click.prevent="showConfirmPassword = !showConfirmPassword"
            :disabled="passwordEmpty"
            id="btnConfirmPass"
            @mouseover="colorEyeConfirmPass = 'color: white;'"
            @mouseleave="colorEyeConfirmPass = 'color: grey;'"
            style="
              cursor: pointer;
              position: absolute;
              top: 30%;
              right: 10%;
              border-radius: 55%;
              border-color: transparent;
            "
          >
            <i class="far fa-eye" :style="colorEyeConfirmPass"></i>
          </button>
        </div>
        <div class="row d-flex justify-space-between">
          <div class="col-4 text-start ps-14 fadeIn third">
            <span>{{ counterConfirmPass }} / 6</span>
          </div>
          <div class="col-8 text-end pe-14" style="color: #f1416c">
            <span v-if="textConfirmError">{{ textConfirmError }}</span>
          </div>
        </div>
        <div
          v-if="!form.confirmPassword"
          class="invalid-feedback text-end pe-8 pb-3"
        >
          <span>กรุณากรอก</span>
        </div>

        <input
          v-model="form.firstName"
          type="text"
          id="first-name"
          class="fadeIn fourth"
          placeholder="First Name"
        />
        <input
          v-model="form.lastName"
          type="text"
          id="last-name"
          class="fadeIn fourth"
          placeholder="Last Name"
        />
        <input
          v-model="form.telNumber"
          type="text"
          id="tel-number"
          class="fadeIn fifth"
          placeholder="Phone Number"
          required
        />

        <select
          v-model="form.role"
          class="form-select fadeIn fifth"
          aria-label="Default select example"
        >
          <option>SUPER ADMIN</option>
          <option>ADMIN</option>
          <option>MANAGER</option>
          <option>BRANCH MANAGER</option>
          <option>STAFF</option>
        </select>

        <select
          v-model="form.companyId"
          class="form-select fadeIn fifth"
          aria-label="Default select example"
          :disabled="
            form.role === 'SUPER ADMIN'
              ? true
              : form.role === 'ADMIN'
              ? true
              : false
          "
          :style="
            form.role === 'SUPER ADMIN'
              ? 'cursor: no-drop'
              : form.role === 'ADMIN'
              ? 'cursor: no-drop'
              : 'cursor: '
          "
          :required="
            form.role === 'MANAGER'
              ? true
              : form.role === 'BRANCH MANAGER'
              ? true
              : form.role === 'STAFF'
              ? true
              : false
          "
        >
          <option v-for="item in companyItems" :key="item.id" :value="item.id">
            {{ item.company_name }}
          </option>
        </select>
        <!-- <div class="row mt-2 text-start fadeIn fifth">
          <div class="form-switch ms-13">
            <input
              v-model="showPassword"
              class="form-check-input me-2"
              type="checkbox"
              id="flexSwitchCheckDefault"
            />
            Show Password
          </div>
        </div> -->
        <input
          @click="submit"
          type="submit"
          class="fadeIn fifth mt-10"
          value="Register"
        />

        <!-- Remind Passowrd -->
        <div id="formFooter">
          <a class="underlineHover" @click="goToYuzu"
            >Powered By PTN Innovation.</a
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import loginApi from "@/api/user/";
import companyApi from "@/api/user/";

import { Decode, Encode } from "@/services";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  data: () => ({
    showPassword: false,
    showConfirmPassword: false,
    isValid: false,

    companyItems: [],

    form: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      telNumber: "",
      role: "ADMIN",
      companyId: null,
    },
    passwordEmpty: true,

    counterPass: 0,
    textPassError: "",

    counterConfirmPass: 0,
    textConfirmError: "",

    colorEyePass: "color: grey;",
    colorEyeConfirmPass: "color: grey;",
  }),
  mounted() {
    this.off();
    this.$refs.username.focus();
    this.getAllCompany();
  },
  methods: {
    async getAllCompany() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await companyApi.company.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.companyItems = responseData.data;
        this.loading = false;
        // this.tableItems = responseData.data;
        // this.loading = false;
      } else {
        this.loading = false;
      }
    },

    async submit() {
      let addResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();

      if (this.isValid) {
        document.getElementById("overlay").style.display = "block";
        delete this.form.confirmPassword;
        try {
          addResponse = await loginApi.register.addUser(this.form);
          if (addResponse.data) {
            if (addResponse.response_status === "SUCCESS") {
              this.autoLogin();
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "เพิ่มไม่สำเร็จ !",
              html: `${addResponse.message}`,
              confirmButtonText: "ตกลง",
            }).then(() => {
              document.getElementById("overlay").style.display = "none";
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.username
        ? (this.isValid = false)
        : !this.form.password
        ? (this.isValid = false)
        : this.form.password.length !== 6
        ? (this.isValid = false)
        : !this.form.confirmPassword
        ? (this.isValid = false)
        : this.form.confirmPassword.length !== 6
        ? (this.isValid = false)
        : this.form.confirmPassword !== this.form.password
        ? (this.isValid = false)
        : !this.form.email
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    async autoLogin() {
      let loginResponse = [];
      document.getElementById("overlay").style.display = "block";
      this.form = {
        username: this.form.username,
        password: this.form.password,
      };
      try {
        loginResponse = await loginApi.login.login(this.form);
      } catch (err) {
        console.log(err);
      }
      if (loginResponse.data) {
        localStorage.setItem(
          "belucaCodex",
          Encode.encode(loginResponse.data.token)
        );
        localStorage.setItem("boss", Encode.encode(loginResponse.data.user.id));
        localStorage.setItem("role", loginResponse.data.user.role);

        Swal.fire({
          icon: "success",
          title: "เพิ่มสำเร็จ",
          html: `สวัสดี <b>${loginResponse.data.user.username}</b> 🥰`,
          showConfirmButton: false,
          timer: 2500,
        }).then(() => {
          if (loginResponse.data.user.companyId) {
            localStorage.setItem(
              "companyId",
              loginResponse.data.user.companyId
            );
            this.$router.replace({
              path: "/dashboard",
              query: {},
            });
          } else {
            this.$router.replace({
              path: "/select-company",
              query: {},
            });
          }
          document.getElementById("overlay").style.display = "none";
        });
      }
    },
    off() {
      document.getElementById("overlay").style.display = "none";
    },
    goBack() {
      this.$router.push("/public");
    },
    goToYuzu() {
      window.open("https://yuzudigital.com/");
    },

    passwordChange(val) {
      if (val) {
        this.counterPass = val.length;

        if (val.length < 6) {
          this.textPassError = "กรุณากรอกให้ครบ 6 หลัก";

          this.passwordEmpty = true;

          // clear validate confirmPass
          this.textConfirmError = "";
          this.form.confirmPassword = "";
          this.counterConfirmPass = 0;
        } else {
          this.textPassError = "";
          this.passwordEmpty = false;
        }
      } else {
        this.counterPass = 0;
        this.passwordEmpty = true;
      }
    },

    confirmPasswordChange(val) {
      if (val) {
        this.counterConfirmPass = val.length;

        if (val !== this.form.password) {
          this.textConfirmError = "กรุณากรอกให้ตรงกับ Password";
        } else {
          this.textConfirmError = "";
        }
      } else {
        this.counterConfirmPass = 0;
      }
    },
  },
};
</script>

<style scoped>
#btnPass:hover {
  background-color: #56baed;
}
#btnConfirmPass:hover {
  background-color: #56baed;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  /* text-transform: uppercase; */
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* text-transform: uppercase; */
  font-size: 13px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 5px 10px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 85%;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
  transform: scale(1.05);
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  /* -moz-transform: scale(0.95); */
  /* -webkit-transform: scale(0.95); */
  /* -o-transform: scale(0.95); */
  /* -ms-transform: scale(0.95); */
  /* transform: scale(0.95); */
}
input[type="text"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="password"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="password"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    /* -webkit-transform: translate3d(0, -100%, 0); */
    /* transform: translate3d(0, -100%, 0); */
  }
  100% {
    opacity: 1;
    /* -webkit-transform: none; */
    /* transform: none; */
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
} */

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fadeIn.fifth {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 1s;
}

.underlineHover:hover {
  cursor: pointer;
  color: #0d0d0d;
  transform: scale(1.1);
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}
.form-select {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
</style>
